.alert {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.alertModal {
  background: #FFFFFF;
  border-radius: 30px;
  width: clamp(328px, 91.1vw, 880px);
  padding: clamp(40px, 11.1vw, 100px) clamp(20px, 5.5vw, 90px);
  position: relative;
  z-index: 101;
}
.alert::after {
  content: '';
  background-color: var(--main-black);
  opacity: 0.9;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
}
.message {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: var(--main-black);
  margin: 0;
  margin-bottom: clamp(32px, 8.8vw, 80px);
}
.closeModal {
  position: absolute;
  top: clamp(14px, 3.8vw, 34px);
  right: clamp(14px, 3.8vw, 34px);
  cursor: pointer;
}