.inputGroup {
  display: flex;
  padding: clamp(25px, 6.9vw, 24px) clamp(24px, 6.6vw, 40px);
  background: #ffffff;
  border: 2px solid var(--secondary-grey);
  border-radius: 50px;
  box-sizing: border-box;
  cursor: text;
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;
  min-width: clamp(288px, 80vw, 500px);
  width: 100%;
}
.inputField {
  border: none;
  flex-grow: 1;
  font-weight: 600;
  font-size: clamp(20px, 5.5vw, 24px);
  line-height: 110%;
  letter-spacing: -0.04em;
  color: var(--main-grey);
  background-color: inherit;
}
