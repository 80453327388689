/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* remove default dot (•) sign */
::marker {
  content: initial;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}

/* Table styles */

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td,
.th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 16px;
}

.tr:nth-child(even) {
  background-color: #dddddd;
}

.td {
  text-align: center;
}

.td > div {
  display: flex;
  gap: 4px;
  justify-content: space-evenly;
  align-items: center;
}

.input-styler,
.textarea-styler {
  width: 300px;
  outline: none;
  border-radius: 6px;
  background-color: #fafafa !important;
  border: 2px solid #898b98;
  color: #101011 !important;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.input-styler {
  height: 40px;
}
.input-styler:focus,
.textarea-styler:focus {
  border-color: #000;
}
.input-styler::placeholder {
  color: #898b98;
}
.textarea-styler {
  height: 70px;
  resize: none;
  outline: none;
  overflow: auto;
  scrollbar-width: none;
}
.textarea-styler::-webkit-scrollbar {
  display: none;
}

.button-styler {
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #41bcd68a;
  outline: none;
  border: 1px solid #898b98;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  color: #000;
  display: inline;
  height: 40px;
  margin: 0px auto;
  font-size: 14px;
  border-radius: 6px;
}
