.inputField {
  outline: none;
  border: none;
  height: 26px;
  margin-top: 26px;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: var(--main-black);
  padding: 0;
}

.inputGroup {
  display: flex;
  padding: clamp(14px, 3.8vw, 20px) clamp(24px, 6.6vw, 40px);
  background: #ffffff;
  border: 1px solid var(--secondary-grey);;
  border-radius: 50px;
  width: 100%;
  box-sizing: border-box;
  cursor: text;
  overflow: hidden;
}
.inputGroup::before {
  content: attr(data-label);
  display: block;
  color: var(--main-grey);
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: clamp(20px, 5.5vw, 24px);
  line-height: 110%;
  position: absolute;
  transform: translateY(13px);
  transition: transform 150ms ease, font-size 150ms ease;
  z-index: 3;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.inputGroup:focus-within::before,
.notEmpty::before {
  transform: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}